@use '../config/' as *;
.waf-club-listing.waf-shape-head {
    padding: 0;
}
.waf-club-listing {
    .waf-head .title {
        @extend %white;
    }
    .waf-body {
        @extend %pt-6;
        @extend %pb-8;
        @extend %px-1;
        @extend %text-center;
    }
    .club-body,
    .club-item {
        &::after {
            content: '';
            width: 8rem;
            height: 8rem;
            filter: invert(1) brightness(0.5);
            opacity: 0.2;
            top: -0.6rem;
            pointer-events: none;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: -3rem -3rem;
            transition: 300ms ease-in-out;
            @include bgImg("svg/hexagon.svg");
            @extend %position-t-l;
        }
    }
    .club {
        &-list {
            display: grid;
            gap: 1.5rem;
            grid-template-columns: repeat(2, 1fr);
        }
        &-item {
            @extend %half-radius;
            @extend %relative;
            @extend %hidden;
            @extend %transition;
        }
        &-head {
            aspect-ratio: 16/9;
            img {
                aspect-ratio: 16/9;
            }
        }
        &-body {
            border-radius: var(--half-radius) var(--half-radius) 0 0;
            background: linear-gradient(125deg, var(--club-primary) 0%, var(--club-secondary) 100%);
            @extend %relative;
            @extend %pt-8;
            @extend %pb-4;
            @extend %mt-1-neg;
            @extend %text-center;
        }
        &-logo {
            width: 5.3rem;
            height: 5.3rem;
            margin-top: -6.5rem;
            outline: 0.3rem solid hsl(var(--hsl-white)/0.2);
            @include bg(white);
            @extend %p-2;
            @extend %mx-auto;
            @extend %mb-2;
            @extend %circle-radius;
        }
        &-img {
            object-fit: contain;
            object-position: center;
        }
        &-text {
            @extend %mb-3;
            .name {
                @extend %white;
                @extend %font-12-pb;
            }
            .name.full-name {
                @extend %d-none;
            }
        }
        &-venue {
            max-width: 90%;
            @include truncate(2, 10, 16);
            @extend %w-100;
            @extend %relative;
            @extend %capitalize;
            @extend %white;
            @extend %mx-auto;
            @extend %text-center;
            &:before {
                @include icon(stadium);
                @extend %mr-1;
            }
        }
    }
    .club-item:after {
        content: unset;
    }
}
// starts: media query
@media (max-width:$tablet-max-width) {
    .waf-club-listing {
        .btn {
            padding: 0;
            border: none;
            position: relative;
            font-size: 0;
            &:after {
                font-size: 1.4rem;
                color: var(--white);
                margin-top: var(--space-1);
                display: inline-block;
                @include icon(chevron-right);
            }
        }
    }
}
@include mq(col-sm) {
    .waf-club-listing {
        .club-list {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}
@include mq(col-lg) {
    .waf-club-listing {
        .club {
            &-list {
                grid-template-columns: repeat(4, 1fr);
            }
            &-body {
                background: transparent;
                transition: 300ms ease-in-out;
                &:after {
                    content: unset;
                }
            }
            &-logo {
                width: 8rem;
                height: 8rem;
                margin: -10rem auto 2.2rem auto;
                transition: 300ms ease-in-out;
                padding: var(--space-2);
                outline: 1rem solid hsl(var(--hsl-white)/0.2);
            }
            &-text {
                .name.short-name {
                    display: none;
                }
                .name.full-name {
                    display: block;
                    padding: 0 1.5rem;
                    font-size: 1.6rem;
                    height: 3.8rem;
                }
            }
            &-venue {
                font-size: 1.2rem;
            }
            &-item {
                &:before {
                    content: '';
                    width: 100%;
                    height: 62%;
                    position: absolute;
                    border-radius: var(--half-radius) var(--half-radius) 0 0;
                    bottom: 0;
                    inset-inline: 0;
                    background: linear-gradient(125deg, var(--club-primary) 0%, var(--club-secondary) 100%);
                    transition: 300ms ease-in-out;
                }
                &:after {
                    content: '';
                    width: 10rem;
                    height: 10rem;
                    top: 37%;
                }
                &:hover {
                    &:before {
                        height: 100%;
                    }
                    &:after {
                        top: 0;
                    }
                    .club {
                        &-body {
                            transform: translateY(-1.2rem);
                            &:after {
                                top: -100%;
                            }
                        }
                        &-logo {
                            width: 5.6rem;
                            height: 5.6rem;
                        }
                    }
                    .btn {
                        display: inline-block;
                        padding: var(--space-2) var(--space-3);
                        border: 0.1rem solid var(--white);
                        font-size: 1.2rem;
                        line-height: 1;
                        color: var(--white);
                        transform: translateY(2.2rem);
                        visibility: visible;
                        opacity: 1;
                        height: auto;
                    }
                }
            }
        }
        .btn {
            display: inline-block;
            transform: translateY(7rem);
            visibility: hidden;
            opacity: 0;
            transition: 300ms ease-in-out;
            padding: 0;
        }
    }
}
@include mq(col-xl) {
    .waf-club-listing {
        .club {
            &-item {
                &:before {
                    height: 60%;
                }
                &:after {
                    top: 40%;
                }
                &:hover {
                    .club-body {
                        transform: translateY(-3.2rem);
                    }
                    .btn {
                        transform: translateY(3.2rem);
                    }
                }
            }
            &-logo {
                margin: -8rem auto 2.2rem auto;
            }
        }
    }
}
// ends: media query